

$(function(){
	// Vars
	var $burger = $('#toggle'),
		$scrollifySection = $('.scrollify'),
		$arrow = $('.arrow'),

		//Typewriter
		//- home
		typeWriter = document.getElementById('one-column__typewriter'),
		$options = $('.option'),
		optionsArray = [],

		//- page
		typeWriterHero = document.getElementById('hero__typewriter'),
		$optionsHero = $('.option_hero'),
		optionsArrayHero = [],

		// Headings
		$headingAfterSmallWord = $('.two-column__after-small-word'),
		$headingSmallWord = $('.two-column__small-word-inject'),

		// Accordion
		$accordion = $('#accordion'),

		// Masonry
		$grid = $('.blog-section__container'),

		// Infinite scroll initiator
		$next_nav = $('.next-nav a'),
		$next_link_text = $('.next_link_text'),

		// Video
		$video = $(".two-column__video"),
		$videoMobile = $(".two-column__video--mobile"),
		$video_mobile = $(".two-column__video--mobile"),
		$hero_video = $('.hero__video'),

		// Fancybox
		$fancy_container = $('.fancybox-container'),

		// Misc
		$window = $(window),
		$next_text = $('.next-section'),
		$heroParagraphs = $('hero__heading p'),
		$404_page = $('.the_404_page'),
		$homeHero = $('.home-hero'),
		$accordionBox = $('.accordion__box');


	// Home - hide arrow when past hero
	
	var $winH =  $window.height();
	if($homeHero.length === 1){
		$window.scroll(function() {
			if ($(this).scrollTop() > $winH) {
				$arrow.css({
					'display': 'none'
				});
			} else if($(this).scrollTop() + $(this).height() == $(document).height()) {
				$('.arrow--up').show();
			} else {
				$arrow.css({
					'display': 'block'
				});
			}
		}).on("resize", function(){ 
			var $winH = $(window).height();
		});
	}

	// Page(what we do) - move 3d content

	if($('#threed').length === 1){
		document.getElementById('threed').appendChild( document.getElementById('application-canvas') );
	}

	
	// All - convert p tags inside H1 tags to spans for semantic validity
	$heroParagraphs.replaceWith(function(){
		return $("<span />").append($(this).contents());
	});

	// Home - set certain height for mobiles
	$scrollifySection.css('height', $(window).height());

	// Nav - insert text into load more button from a custonm field
	$next_nav.html($next_link_text.html());


	// Home - typewriter array
	$options.each(function(){
		optionsArray.push($(this).html().trim());
	});
	
	// Home - typewriter
	if(typeWriter){
		$(".one-column__typewriter").typed({
			strings: optionsArray,
			// Optionally use an HTML element to grab strings from (must wrap each string in a <p>)
			stringsElement: null,
			// typing speed
			typeSpeed: 70,
			// time before typing starts
			startDelay: 500,
			// backspacing speed
			backSpeed: 30,
			// time before backspacing
			backDelay: 1500,
			// loop
			loop: true,
			// false = infinite
			loopCount: false,
			// show cursor
			showCursor: false,
			// character for cursor
			cursorChar: "|",
			// attribute to type (null == text)
			attr: null,
			// either html or text
			contentType: 'html',
			// call when done callback function
			callback: function() {},
			// starting callback function before each string
			preStringTyped: function() {},
			//callback for every typed string
			onStringTyped: function() {},
			// callback for reset
			resetCallback: function() {}
		});
	}

	// page - attain typewriter options
	$optionsHero.each(function(){
		optionsArrayHero.push($(this).html().trim());
	});

	// page (where to find us ) - typewriter
	if(typeWriterHero){
		$(".hero__typewriter").typed({
			strings: optionsArrayHero,
			// Optionally use an HTML element to grab strings from (must wrap each string in a <p>)
			stringsElement: null,
			// typing speed
			typeSpeed: 70,
			// time before typing starts
			startDelay: 500,
			// backspacing speed
			backSpeed: 30,
			// time before backspacing
			backDelay: 2000,
			// loop
			loop: true,
			// false = infinite
			loopCount: false,
			// show cursor
			showCursor: false,
			// character for cursor
			cursorChar: "|",
			// attribute to type (null == text)
			attr: null,
			// either html or text
			contentType: 'html',
			// call when done callback function
			callback: function() {},
			// starting callback function before each string
			preStringTyped: function() {},
			//callback for every typed string
			onStringTyped: function() {},
			// callback for reset
			resetCallback: function() {}
		});
	}

	//Home - add padding to next link 
	(function addPadding() {
		$next_text.each(function(){
			if($(this).html().length >= 8) {
				$(this).css('padding-right', '35px');
				return;
			} else if($(this).html().length >= 7) {
				$(this).css('padding-right', '18px');
				return;
			} else if($(this).html().length >= 6) {
				$(this).css('padding-right', '11px');
				return;
			} else {
				$(this).css('padding-right', '13px');
				return;
			}
		});
	})();
	
	// All - burger
	$burger.click(function() {
		$(this).toggleClass('active');
		$('#overlay').toggleClass('open');
		$('.icons-color').toggleClass('icons-color-important');
		$('.icons-background-color').toggleClass('icons-background-color-important');
		$('.page-icons-color').toggleClass('page-icons-color-important');
		$('.page-icons-background-color').toggleClass('page-icons-background-color-important');
		$('.hero').toggleClass('hero--100');
		$('.masthead-container').toggleClass('masthead-container--100');
		$('.absolute').toggleClass('fixed');
	});

	// Home -fancybox / Who we work with - hero video
	$video.fancybox({
		idleTime: false
	});

	// page - video lightbox
	$video_mobile.fancybox({
		idleTime: false
	});

	// Home - video lightbox
	$hero_video.fancybox({
		idleTime: false
	});

	// $fancy_container.addClass('fancybox-show-toolbar');

	// Home - scrollify
	$.scrollify({
		scrollbars: false,
		setHeights: false,
		section : $scrollifySection,
		scrollSpeed: 700,
		touchScroll: true,
		overflowScroll: false,
		easing: "easeOutExpo",
		offset : 0,
		before: function(i, e) {
			var color = e[i].data().color;
			$('.icons-color').css('color', color);
			$('.icons-background-color').css('background-color', color);
			var $footerStrip = $('.footer__strip--home'),
			$icons = $('.masthead__icons'),
			$logo = $('.masthead__logo');
			
			if(i === 6){
				$icons.addClass('masthead__icons--top');
				$logo.addClass('masthead__logo--top');
				if($(window).width() < 500){
					$icons.fadeToggle()
					$logo.fadeToggle();
				};
			} else {
				$icons.fadeIn();
				$logo.fadeIn();
				$icons.removeClass('masthead__icons--top');
				$logo.removeClass('masthead__logo--top');
			}
		}
	});

	//Home - heading small word 
	$headingAfterSmallWord.before('<span class="two-column__small-word-actual">' + $headingSmallWord.html() + '</span>');

	//About - Accordion
	$accordion.find('.accordion-toggle').click(function(){
      //Expand or collapse this panel
      $(this).next().slideToggle('fast');

      //Hide the other panels
    //   $(".accordion-content").not($(this).next()).slideUp('fast');

	});

	// All - initiate masonry
	$grid.masonry({
		stagger: 30,
		itemSelector: '.blog-section__post',
		columnWdth: 279,
		gutter: 32
	});

	// All - await images for infinite scroll
	$grid.imagesLoaded().progress(function () {
		$grid.masonry('layout');
		$grid.masonry('option', {itemSelector: '.post'});
	});

	// All - masonry instance
	var msnry = $grid.data('masonry');

	// All - Initiate infinite scroll
	$grid.infiniteScroll({
		path: '.next-nav a',
		append: '.blog-section__post',
		history: true,
		outlayer: msnry,
		loadOnScroll: false
	  });


	// Initiate infinite scroll when user clicks load more button
	var $viewMoreButton = $('.next-nav a');
		$viewMoreButton.on( 'click', function(e) {
		e.preventDefault();
		// load next page
		$grid.infiniteScroll('loadNextPage');
		// enable loading on scroll
		$grid.infiniteScroll( 'option', {
			loadOnScroll: true,
		});
		// hide button
		$viewMoreButton.hide();
	});

	// Append posts when fully loaded in from infinite scroll
	$grid.on('append.infiniteScroll', function (event, response, path, items) {
		$(items).addClass('appended-item');
		$(items).find('img').each(function (index, img) {
			img.outerHTML = img.outerHTML;
		});

		$grid.imagesLoaded().progress(function () {
			$grid.masonry('layout');
			$grid.masonry('option', {itemSelector: '.blog-section__post'});
			$.fn.matchHeight._update();

		});
	});
	

	// All - typeform(external) form block
	(function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })();

	// 404
	if($($404_page).length > 0){
		$('.current_page_parent a').addClass('black');
	}
	  

});